import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Capsule Endoscopy";

// Download link - http://www.fbrennangastro.com.au/downloads/gesacapsuleendoscopy.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "Bowel bleeding diagnosis", "Bowel tumours", "Abdominal pain", "Bowel camera"]}
			type="article"
			description="What is capsule endoscopy, why would you undergo capsule endoscopy and how it works"
		/>
		<H1>{title}</H1>
		<Par>
			This procedure involves the ingestion of a small capsule that contains a tiny camera, which can take 2-4 images
			per second for eight hours. It has been specifically developed to view the small bowel.
		</Par>
		<Par>
			Capsule endoscopy is used to try and pinpoint bleeding in hidden areas in the small bowel. It is also useful for
			tracking small bowel tumours and obscure abdominal pain.
		</Par>
		<Par>
			The capsule is swallowed and travels, just like a piece of food, through the stomach, small bowel and into the
			large bowel. Recording probes, taped onto the abdomen, track the progress of the capsule as it makes its way
			through the gastrointestinal system. The images obtained by the capsule are transmitted to a data recorder worn in
			a harness around the waist.
		</Par>
	</Blog>
);
